(function () {
    'use strict';
    $(document).ready(function () {

        $(".play-video").modalVideo();

        // Smooth scroll to
        $('.scroll-animation').on('click', function (e) {
            e.preventDefault();

            const href = $(this).attr('href');
            if (href) {
                $("html, body").animate({
                    scrollTop: $(href).offset().top
                }, 1000);
            }
        });

        $('.scroll-animation a').on('click', function (e) {
            e.stopPropagation();
        });

        //Menu mobile toggle
        $(".menu-toggle").click(function(){
            $('.menu-overlay').slideToggle();
            $(this).toggleClass("open");
        });

        //Search trigger
        $('.search-trigger').click(function () {
            $(this).toggleClass('active');
            $('.search-panel').fadeToggle('active');
            return false;
        });

        //Footer dropdown
        $("#show-previous-reports").click(function(a) {
            $(this).toggleClass("active"),
                $(".previous-reports-list").slideToggle(),
                a.preventDefault();
        });

        //Go top
        $('footer .go-top').click(function () {
            $('html, body').animate({
                scrollTop: 0
            }, 'slow');
            return false;
        });
        if ($(window).scrollTop() > 500)
            $('footer .go-top').addClass('show');
        $(window).scroll(function () {
            if ($(window).scrollTop() > 500)
                $('footer .go-top').addClass('show');
            else
                $('footer .go-top').removeClass('show');
        });

    });
}(jQuery));
