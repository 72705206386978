(function () {
    'use strict';
    $(document).ready(function () {

        // SOCIAL SHARING
        const $share = $('#share');
        $share.css('opacity', 1);
        $share.jsSocials({
            showLabel: false,
            showCount: false,
            shareIn: "popup",
            text: document.title,
            url: window.location.href,
            shares: [
                {
                    share: "facebook",
                    logo: `${$share.attr('data-templateURL')}/dist/images/facebook.svg`,
                    inlineSvg: true,
                },
                {
                    share: "twitter",
                    logo: `${$share.attr('data-templateURL')}/dist/images/twitter.svg`,
                    inlineSvg: true,
                    via: "pgnig",
                },
                {
                    share: "linkedin",
                    logo: `${$share.attr('data-templateURL')}/dist/images/linkedin.svg`,
                    inlineSvg: true,
                }
            ]
        });

        $('.top .indexes .index-label').on('click', function() {
            $(this).toggleClass('open');
            $(this).siblings('.index-list').slideToggle({
                start: function () {
                    $(this).css({
                        display: "flex"
                    })
                }
            });
        });


        //tools show/hide
        $('#tools-toggle').click(function () {
            $('.tools-list').animate({
                height: 'toggle'
            });
            $(this).toggleClass('opened');
        });

        ScrollTrigger.create({
            trigger: ".subpage .top",
            start: "bottom center",
            endTrigger: ".subpage .pages",
            end: "top center",
            onEnter: function(){
                gsap.to(".aside-tools", {
                    autoAlpha: 1,
                    duration: .5,
                });
            },
            onEnterBack: function(){
                gsap.to(".aside-tools", {
                    autoAlpha: 1,
                    duration: .5,
                });
            },
            onLeave: function(){
                gsap.to(".aside-tools", {
                    autoAlpha: 0,
                    duration: .5,
                });
            },
            onLeaveBack: function(){
                gsap.to(".aside-tools", {
                    autoAlpha: 0,
                    duration: .5,
                });
            }
        });

        // accordion scroll animation
        $('.subpage .accordion.scroll-animation .collapse').on('shown.bs.collapse', function (e) {
            updateTableSize();
            var positionTop = $(this).parent().offset().top; // - $('header').height();
            $('html, body').animate({
                scrollTop: positionTop
            }, 500);
            $(window).resize();
        });

        // responsive image enlarging
        $('.popup-trigger').click(function (e) {
            $(this).parent().find('.popup-image-container').fadeIn();
            e.preventDefault();
            return false;
        });

        $('.popup-image-container').click(function () {
            $(this).fadeOut();
        });

        //Tooltipster

        // default tooltips
        $('.default-tooltipster').tooltipster({
            maxWidth: 290,
            contentAsHTML: true,
            interactive: true,
            functionPosition: function (instance, helper, position) {
                position.coord.left = helper.geo.origin.offset.left - 20;
                return position;
            },
        });

        function updateTableSize() {
            $('.table-container').each(function () {
                if ($(this).parent().hasClass('panel-body')) return;
                var containerWidth = $(this).width();
                var tableWidth = $('table', this).width();

                if (tableWidth > containerWidth) {
                    $(this).addClass('scrollable');
                    $(this).mCustomScrollbar({
                        axis: "x",
                        mouseWheel: {
                            enable: true
                        }
                    });
                } else {
                    $(this).removeClass('scrollable');
                    $(this).mCustomScrollbar('destroy');
                }
            });
        }

        var swiperTextSlider = new Swiper('.text-image-slider .swiper-text', {
            navigation: {
                prevEl: '.text-image-slider .swiper-button-prev',
                nextEl: '.text-image-slider .swiper-button-next',
            },
        });

        var swiperImageSlider = new Swiper('.text-image-slider .swiper-image', {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
        });

        if($('.text-image-slider .swiper-text').length > 1){

            $('.text-image-slider .swiper-text').each(function (a){

                swiperImageSlider[a].controller.control = swiperTextSlider[a];
                swiperTextSlider[a].controller.control = swiperImageSlider[a];

            });

        }
        else if ($('.text-image-slider .swiper-text').length == 1){
            swiperImageSlider.controller.control = swiperTextSlider;
            swiperTextSlider.controller.control = swiperImageSlider;
        }


        $('.quotation-slider .swiper-quote').each(function () {
            var swiperId = $(this).attr('id');
            var swiper = new Swiper("#" + swiperId, {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                autoHeight: true,
                navigation: {
                    nextEl: '#' + swiperId + ' .swiper-button-next',
                    prevEl: '#' + swiperId + ' .swiper-button-prev',
                },
            })
        })



    });
}(jQuery));
