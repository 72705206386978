(function () {
    'use strict';
    $(document).ready(function () {

        var swiperCTAslider = new Swiper('.swiper-cta-slider', {
            loop: true,
            allowTouchMove: false,
            autoplay: {
                delay: 10000,
            },
        });

        var swiperDevelopmentQuotes = new Swiper('.swiper-development-quotes', {
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
        });

        var swiperContent = new Swiper('.swiper-content', {
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
        });

        $('.quote-avatar').on('click', function(e) {
            e.preventDefault();
            var dataQuote = $(this).data('quote');
            swiperDevelopmentQuotes.slideTo(dataQuote);
        });

    });
}(jQuery));
