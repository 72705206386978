(function () {
    'use strict';

    var pageUrl = document.location.href.replace("http://", "");
    var useHighlighter = false;
    var highlightedElements = [];

    var headElement = document.head || document.getElementsByTagName('head')[0];
    var fontStyleElement = document.createElement('style');

    var fontSizeType = {
        min: 'min',
        normal: 'normal',
        max: 'max'
    };

    var actualFontSizeType = fontSizeType.normal;

    function getFontSizes() {
        var sizes = {
            normal: 62.5,
            min: 47.5,
            max: 77.5
        };
        if ($(window).width() >= 1025 && $(window).width() < 1200) {
            sizes.normal = 55;
            sizes.min = 40;
            sizes.max = 70;
        } else if ($(window).width() < 1025) {
            sizes.normal = 45;
            sizes.min = 30;
            sizes.max = 60;
        }
        return sizes;
    }

    function setFontSize(type, setLocalStorage) {
        var size, css;
        if (type === fontSizeType.min)
            size = getFontSizes().min;
        else if (type === fontSizeType.max)
            size = getFontSizes().max;
        else
            size = getFontSizes().normal;

        css = 'html { font-size: ' + size + '%; }';
        if (fontStyleElement.styleSheet) {
            fontStyleElement.styleSheet.cssText = css;
        } else {
            while (fontStyleElement.firstChild) {
                fontStyleElement.removeChild(fontStyleElement.firstChild);
            }
            fontStyleElement.appendChild(document.createTextNode(css));
        }

        if (setLocalStorage)
            localStorage.setItem('font-size', type);
    }

    function handleFontSize() {
        // on start
        fontStyleElement.type = 'text/css';
        headElement.appendChild(fontStyleElement);
        if (localStorage.getItem('font-size')) {
            actualFontSizeType = localStorage.getItem('font-size');
            setFontSize(actualFontSizeType, false);
        }

        // events
        $('.font-trigger').on('click', function (e) {
            if (actualFontSizeType == fontSizeType.normal)
                actualFontSizeType = fontSizeType.max;
            else if (actualFontSizeType == fontSizeType.max)
                actualFontSizeType = fontSizeType.min;
            else if (actualFontSizeType == fontSizeType.min)
                actualFontSizeType = fontSizeType.normal;

            setFontSize(actualFontSizeType, true);
            e.preventDefault();
            return false;
        });
    }

    function handleContrast() {
        // on start
        if (localStorage.getItem('contrast') && localStorage.getItem('contrast') === 'on') {
            $('.contrast-trigger').addClass('active');
            $('body').addClass('contrast');
        }

        // events
        $('.contrast-trigger').on('click', function (e) {
            if (localStorage.getItem('contrast') && localStorage.getItem('contrast') === 'on') {
                localStorage.setItem('contrast', 'off');
                $('.contrast-trigger').removeClass('active');
            } else {
                localStorage.setItem('contrast', 'on');
                $('.contrast-trigger').addClass('active');
            }
            $('body').toggleClass('contrast');
            e.preventDefault();
            return false;
        });
    }

    function storeHighlightCookie() {
        var joined = highlightedElements.join('||');
        localStorage.setItem('hlt' + pageUrl, joined);
    }

    function restoreHighlightCookie() {
        var value = localStorage.getItem('hlt' + pageUrl);
        if (!value) return;
        highlightedElements = value.split('||');
        highlightedElements.forEach(function (elementXPath) {
            var el = $(lookupElementByXPath(elementXPath));
            el.addClass('highlighted');
        })
    }

    function createXPathFromElement(elm) {
        var allNodes = document.getElementsByTagName('*');
        var i, sib;
        for (var segs = []; elm && elm.nodeType == 1; elm = elm.parentNode) {
            for (i = 1, sib = elm.previousSibling; sib; sib = sib.previousSibling) {
                if (sib.localName == elm.localName) i++;
            }
            ;
            segs.unshift(elm.localName.toLowerCase() + '[' + i + ']');
        }
        ;
        return segs.length ? '/' + segs.join('/') : null;
    };

    function lookupElementByXPath(path) {
        var evaluator = new XPathEvaluator();
        var result = evaluator.evaluate(path, document.documentElement, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null);
        return result.singleNodeValue;
    }

    function handleHighlighter() {
        // on start
        restoreHighlightCookie();
        // events
        $('.use-highlighter').click(function (e) {
            $(this).toggleClass('active');
            if (!useHighlighter) {
                $('.subpage').addClass('with-highlighter');
                $(document).keyup(function (e) {
                    if (e.keyCode == 27) {
                        $('.subpage').removeClass('with-highlighter');
                        useHighlighter = false;
                    }
                });
            } else $('.subpage').removeClass('with-highlighter');
            useHighlighter = !useHighlighter;
            e.preventDefault();
            return false;
        });
        $('.tools-trigger, .tools-panel a').not('#use-highlighter').click(function () {
            useHighlighter = false;
            $('.subpage').removeClass('with-highlighter');
            // return false;
        });
        $('.subpage').find(':not(:has(*:not(br):not(b):not(strong):not(span):not(a))):not(.tools-panel *)').click(function (event) {
            if (!useHighlighter) return;
            var el = $(event.target);
            if (!el.hasClass('highlighted')) {
                el.addClass('highlighted');
                highlightedElements.push(createXPathFromElement(event.target));
            } else {
                el.removeClass('highlighted');
                var indexOf = highlightedElements.indexOf(createXPathFromElement(event.target));
                highlightedElements.splice(indexOf, 1);
            }
            storeHighlightCookie();
        });
    }

    function handlePrintVersion() {
        $('.use-print-version').click(function (e) {
            $('body').addClass('print-version');
            toggleAccordions(true);
            $(window).trigger('resize');
            updateSwipers();
            e.preventDefault();
            return false;
        });
        $('.hide-print-version').click(function () {
            $('body').removeClass('print-version');
            toggleAccordions(false);
            $(window).trigger('resize');
        });
    }

    function showPrintBasket() {
        if ($('.print-list'.length)) {
            var emptyBasketText = $('.tools-fields #field-empty-basket').val();
            var sendToPrinterText = $('.tools-fields #field-send-to-printer').val();
            var deleteText = $('.tools-fields #field-delete').val();

            var printBasketData = localStorage.getItem('printbasket');
            var printBasket = printBasketData ? JSON.parse(printBasketData) : [];
            var contentEl = $('.print-list');
            contentEl.html('');
            if (printBasket.length === 0) {
                contentEl.append('<div class="col empty"><h2>' + emptyBasketText + '</h2></div>');
            } else {
                var index = 0;
                var results = $('<div class="results">');
                printBasket.forEach(function (storedPage) {
                    var printContent = $('<div class="print-content">');
                    printContent.append(storedPage.html);
                    var printPage = $('<div class="row">');
                    var title = printContent.find('.top h1').text();
                    printPage.append('<div class="col-8 title"><a href="' + storedPage.url + '" >' + title + '</a></div>');
                    printPage.append('<div class="col-4 delete"><a href="#" class="remove-from-print-basket" data-printindex="' + index + '" aria-label="Usuń z koszyka">' + '<span>' + deleteText + '</span></a></div>');
                    printPage.append(printContent);
                    results.append(printPage);
                    index++;
                });
                contentEl.append(results);
                contentEl.append('<div class="buttons"><button class="btn btn-default btn-brand send-to-printer">' + sendToPrinterText + '</button></div>');
            }
        }
    }

    function handlePrintBasket() {
        showPrintBasket();
        $('.add-to-print-basket').click(function (e) {
            $('body').addClass('print-version');
            $(window).trigger('resize');
            updateSwipers();
            setTimeout(function () {
                var printBasketData = localStorage.getItem('printbasket');
                var printBasket = printBasketData ? JSON.parse(printBasketData) : [];
                var contentEl = $('.subpage')[0];
                var existsAlready = printBasket.filter(function (storedPage) {
                    return storedPage.url === pageUrl
                }).length > 0;
                if (!existsAlready) {
                    printBasket.push({
                        url: pageUrl,
                        html: contentEl.innerHTML
                    });
                    localStorage.setItem('printbasket', JSON.stringify(printBasket));
                }
                $('body').removeClass('print-version');
                updateSwipers();
                setTimeout(function () {
                    $(window).trigger('resize');
                }, 100)
            }, 1000);
            $('.tools-ul').fadeOut();
            e.preventDefault();
            return false;
        });
        $('body').on('click', '.remove-from-print-basket', function (e) {
            var index = $(this).data('printindex');
            var printBasketData = localStorage.getItem('printbasket');
            var printBasket = JSON.parse(printBasketData);
            printBasket.splice(index, 1);
            localStorage.setItem('printbasket', JSON.stringify(printBasket));
            showPrintBasket();
            e.preventDefault();
            return false;
        });
        $('body').on('click', '.send-to-printer', function () {
            var w = window.open();
            var htmlEl = $('html').clone();
            var body = htmlEl.find('body');
            body.html('<div class="subpage printpage"></div>');
            $('.print-content').each(function () {
                body.find('.subpage').append($(this).html());
            });
            var html = htmlEl.html();
            $(w.document.body).html(html).addClass('print-version');
            location.reload();
        });

    }

    function showNotes() {
        if ($('.notes-list'.length)) {
            var noNotesText = $('.tools-fields #field-no-notes').val();
            var deleteText = $('.tools-fields #field-delete').val();

            var notesData = localStorage.getItem('notes');
            var notes = notesData ? JSON.parse(notesData) : [];
            var contentEl = $('.notes-list');
            contentEl.html('');
            if (notes.length == 0) {
                contentEl.append('<div class="col empty"><h2>' + noNotesText + '</h2></div>');
            } else {
                var index = 0;
                var results = $('<div class="results">');
                notes.forEach(function (note) {
                    var notePage = $('<div class="row">');
                    notePage.append('<div class="col-8 title"><a href="' + note.url + '" >' + note.title + '</a></div>');
                    notePage.append('<div class="col-4 delete"><a href="#" class="remove-from-notes" data-noteindex="' + index + '">' + '<span>' + deleteText + '</span></a></div>');
                    var noteNode = $('<div class="col-12 contents"></div>');
                    noteNode.text(note.text);
                    notePage.append(noteNode);
                    results.append(notePage);
                    index++;
                });
                contentEl.append(results);
            }
        }
    }

    function handleNotes() {
        showNotes();
        var title = $('.top h1').text();
        var notesData = localStorage.getItem('notes');
        var notes = notesData ? JSON.parse(notesData) : [];
        var thisPageNotes = notes.filter(function (note) {
            return note.url === pageUrl;
        });
        var thisPageNote = thisPageNotes.length > 0 ? thisPageNotes[0] : null;
        if (thisPageNote) $('.notes-tool textarea').val(thisPageNote.text);
        $('body').on('click', '.notes-tool .save-note', function (e) {
            var text = $('.notes-tool textarea').val();
            if (text === "") {
                $(".note-alert").remove();
                $(".notes-tool").append($("<p class=\"text-center note-alert\" role=\"alert\">" + $('#field-note-cant-be-empty').val() + "</p>"));

                setTimeout(function () {
                    $('.notes-tool .note-alert').slideUp(function () {
                        $(this).empty();
                    });
                }, 5000);

                return false;
            }

            if (thisPageNote)
                thisPageNote.text = text;
            else {
                thisPageNote = {
                    url: pageUrl,
                    title: title,
                    text: text
                };
                notes.push(thisPageNote);
            }
            var notesData = JSON.stringify(notes);
            localStorage.setItem('notes', notesData);
            $(".note-alert").remove();
            $(".notes-tool").append($("<p class=\"text-center note-alert\" role=\"alert\">" + $('#field-note-saved').val() + "</p>"));

            setTimeout(function () {
                $('.notes-tool .note-alert').slideUp(function () {
                    $(this).empty();
                });
            }, 5000);

            $(window).trigger('resize');
            e.preventDefault();
            return false;
        });
        $('body').on('click', '.notes-tool .delete-note', function (e) {
            if (thisPageNote) {
                var index = notes.indexOf(thisPageNote);
                notes.splice(index, 1);
                var notesData = JSON.stringify(notes);
                localStorage.setItem('notes', notesData);
            }
            $('.notes-tool textarea').val('');
            $('.notes-tool').hide();
            $(window).trigger('resize');
            e.preventDefault();
            return false;
        });
        $('body').on('click', '.notes-tool .close-note', function (e) {
            $('.notes-tool').hide();
            $(window).trigger('resize');
            $(".note-alert").hide();
            e.preventDefault();
            return false;
        });
        $('body').on('click', '.add-note', function (e) {
            $('.notes-tool').show();
            $('html, body').animate({
                scrollTop: $('.notes-tool').offset().top - 45
            });
            $(".note-alert").remove();
            $(window).trigger('resize');
            e.preventDefault();
            return false;
        });
        $('body').on('click', '.remove-from-notes', function (e) {
            var index = $(this).data('noteindex');
            var notesData = localStorage.getItem('notes');
            var notes = JSON.parse(notesData);
            notes.splice(index, 1);
            localStorage.setItem('notes', JSON.stringify(notes));
            showNotes();
            e.preventDefault();
            return false;
        });
    }

    function updateSwipers() {
        $('.subpage .swiper-container').each(function () {
            var swiper = $(this)[0].swiper;
            console.log(swiper);
            if (swiper) {
                swiper.slideTo(0);
                setTimeout(function () {
                    swiper.update(true);
                }, 100);
            }
        });
    }

    function toggleAccordions(show) {
        if (show) {
            $('.accordion [data-toggle="collapse"], .accordion [data-toggle="pill"]').removeClass('collapsed');
            $('.accordion .collapse').addClass('show');
        } else {
            $('.accordion [data-toggle="collapse"], .accordion [data-toggle="pill"]').addClass('collapsed');
            $('.accordion .collapse.show').removeClass('show');
        }
    }

    function handleSearch() {

        $('.search-results-modal .close-modal').click(function () {
            $('.search-results-modal').fadeOut();
        });

        $('.results-container').mCustomScrollbar({
            axis: "y",
            mouseWheel: {
                enable: true
            }
        });

    }

    function handleGriIndex() {
        // --- search gri indicators ---
        $("#search-gri").keyup(function () {
            var text = $(this).val();
            if (text.length < 2) {
                if ($('.gri-indicators').hasClass('search')) $('.gri-indicators').removeClass('search');
                return;
            }
            $('.gri-indicators').addClass('search');
            $('.gri-indicators >li').removeClass('found');
            $('.gri-indicators >li').each(function () {
                var title = $('>a', this).text();
                if (title.toLowerCase().indexOf(text.toLowerCase()) > -1) $(this).addClass('found');
            });
        });
        // --- show gri pages ---
        $('.gri-indicators >li >a').click(function (e) {
            $(this).parent().find('ul').slideToggle();
            return false;
        });
    }

    function inputPressed(input) {

        var phrase = $(input).parent().find('input').val();

        var bases = document.getElementsByTagName('base');
        var baseHref = "";
        if (bases.length > 0)
            baseHref = bases[0].href;

        $.getJSON(baseHref + "/wp-content/themes/wp-bootstrap-gulp/search.json").done(function (data) {

            $(".results-list").find('*').remove();
            $(".phrase-container").text(phrase);

            phrase = phrase.toLowerCase();
            var foundTitles = [];
            //Search in titles first
            for (var i = 0; i < data.length; i++) {
                if (foundTitles.indexOf(data[i].Title) === -1) {
                    if (data[i].Title.toLowerCase().indexOf(phrase) !== -1) {
                        foundTitles.push(data[i].Title);
                        $(".results-list").append(
                            '<li><a href="' + data[i].Url + '">' + data[i].Title + '</a></li>'
                        );
                    }
                }
            }
            //Search in contents
            for (var i = 0; i < data.length; i++) {
                if (foundTitles.indexOf(data[i].Title) === -1) {
                    if (data[i].Content.toLowerCase().indexOf(phrase) !== -1) {
                        foundTitles.push(data[i].Title);
                        $(".results-list").append(
                            '<li><a href="' + data[i].Url + '">' + data[i].Title + '</a></li>'
                        );
                    }
                }
            }

            // $('.search-results-modal').fadeIn();
            $('.search-results-dropdown').fadeIn();
        })
    }

    $(document).ready(function () {
        var body = $('body');
        pageUrl = $('#field-pageURL').val();

        $(".search-input").on('keyup', function (e) {
            if (e.keyCode === 27) {
                this.blur();
                return;
            }
            // if (e.keyCode === 13) {
            inputPressed(this);
            // }
        });
        $(".search-input").on('focusout', function () {
            $('.search-results-dropdown').fadeOut();
        });


        $('.perform-search').click(function () {
            inputPressed(this);
            $(".search-input").focus();
        });

        handleFontSize();
        handleContrast();
        handleHighlighter();
        handlePrintVersion();
        handlePrintBasket();
        handleNotes();
        handleSearch();
        handleGriIndex();

    });
}(jQuery));
